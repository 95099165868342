import * as React from "react"
import '../style/style.scss'
import {Button} from "react-bootstrap";

const ReadmePage = () => {
    return (<div className="App unlocked p-5">
        <h1>Read Me</h1>
        <h2>Welcome to Elimination Draw</h2>
        <h3>Instructions</h3>
        <ol>
            <li>Click on the numbers to eliminate them.</li>
            <li>Click anywhere or press any key to dismiss the popup.</li>
            <li>If you accidentally removed a number, click on "Last Eliminated" to reactivate it.</li>
            <li>To make the last number drawn reappear, click on "Numbers called".</li>
            <li>To restart, click on "Elimination Draw"</li>
        </ol>
        <h3>Edit mode</h3>
        <ol>
            <li>Click on the lock in the top right.</li>
            <li>Clicking on a block will cycle through active --> selected (drawn) --> deleted --> active ...</li>
        </ol>
        <h3>Legend</h3>
        <div><Button className={`bg-light text-dark opacity-75 d-inline-block rounded-3 text-center py-2`} style={{fontSize:15, lineHeight: 1, padding: 0, border: 'none', width:55, height: 55, margin: '0 10px 10px 0'}}>1</Button><span>Active</span></div>
        <div><Button className={`bg-light text-dark opacity-75 d-inline-block rounded-3 text-center py-2 dimmed`} style={{fontSize:15, lineHeight: 1, padding: 0, border: 'none', width:55, height: 55, margin: '0 10px 10px 0'}}>2</Button><span>Selected (drawn)</span></div>
        <div><Button className={`bg-light text-dark opacity-75 d-inline-block rounded-3 text-center py-2 deleted`} style={{fontSize:15, lineHeight: 1, padding: 0, border: 'none', width:55, height: 55, margin: '0 10px 10px 0'}}>3</Button><span>Deleted (in edit mode)</span></div>
    </div>)
}

export default ReadmePage